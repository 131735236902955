export function makeLpTitle(title) {
  const siteName = "���Ĵ�ý";

  /* In some cases, editors have already appended the title tag, skip those */
  if (title?.includes("- ���Ĵ�ý")) {
    return title;
  }

  return title ? `$ - ${siteName}` : siteName;
}
